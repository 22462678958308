/**
 * main.ts
 * contains the whole logic of the project
 * uses classes that are defined
 * in elements,components or layouts
**/


var locations = null;

function GetURLParameter(sParam:any) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
}

/**
 * Document-ready function
**/

$(document).ready(function() {


    $('.l-hero__image video').each(function() {
        this.controls = true;

        // var playpause = $('<span class="playpause">Abspielen</span>');
        //     $(this).parent().append(playpause);


        $(this).on('play', function() {
            $(this).next('.playpause').fadeOut();
            $(this).parents('.l-hero').addClass('js-l-hero-play');
            this.controls = true;
        });
        $(this).on('pause', function() {
            $(this).next('.playpause').fadeIn();
            $(this).parents('.l-hero').removeClass('js-l-hero-play');
            this.controls = false;
        });

    });

    $('.playpause').click(function () {
        $(this).parents().find('video').get(0).play();
    });

    /**
     * Initalise function variables
    **/





    /**
     * Basics
     * general scripts required for multiple components and elements
    **/

    let base = new Base();



    /**
     * Sidebar
    **/

    let sidebar = new Sidebar();


    /**
     * Content-Cols - same Header height script
    **/

   let contentcols = new ContentCols();



    /**
     * Flyout-nav
    **/

   let flyoutNav = new FlyoutNav();



    /**
     * Cookie message
    **/

//    let cookieMessage = new CookieMessage();



   /**
     * Forms
    **/

    // let form = new Formfloat();



    /**
     * Navigation
     * Parameters:
     * accordionMode: Close already open menu tabs
     * openCurrent: open active menu tab on load
     * animationDuration
     * overviewpagesPossible: Possiblity to open a menu point directly
    **/

    let panelnav = new Panelnav(true, true, 500, true);



	/**
     * Landingpagenavigation
     * Parameters:
     * animationDuration
	**/

   	if($('.js-l-body').hasClass('js-l-body--landingpage')) {
		let landingpagenavigation = new Landingpagenavigation();
	}


    /**
     * To-Top-Button
     * Parameters:
     * animationDuration
    **/

    let totop = new toTop();



    /**
     * Header
    **/

   let header = new Header();



    /**
     * Pageloader
     * Parameters:
     * maximumTimeVisible
    **/

    let pageloader = new pageLoader();



    /**
     * Hero component
     * Parameters:
     * animationDuration
     * scrollFactor
    **/

    let hero = new Hero(600,0.7);



    /**
     * Slider
     * Parameters:
     * maximumTimeVisible
    **/

    let slider = new Slider();



    /* Watchlist */

	// let watchlist = new Watchlist();


	/**
	 * Filter for tables
	**/

	let filter = new Filter();

});
/**
 * base.ts
 * 
 * 
**/


/* Declare google globally */

declare var Stickyfill: any; 
declare var ClipboardJS: any; 


class Base {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* initTableSorter - JS-Lib for sortable tables */

		self.initTableSorter();


		/* initStickyElements - Stickyfill for several browsers */

		self.initStickyElements();
		

		/* picturefill */

		picturefill(); 


		/* Apply clipboard function */

		self.initClipboardElements();

		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function backLink():void
	 * creates / get a back link from document referrer or history
	**/

	protected backLink():void {
		$('.js-base__back-link').on('click', function() {
			let backURL = $(this).data('back-url');
			if (document.referrer.indexOf(window.location.host) !== -1) { history.go(-1); return false; } else { window.location.href = backURL; }
		});
	}



	/**
	 * Function anchorAnimation():void
	 * initialises / calls the tablesorter (external lib)
	**/

	protected anchorAnimation():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Listen on anchors with hashes
		**/

		$('.e-rte a[href^="#"]').on('click', function (event:any) {

			/* Prevent default behavior of anchors */

			event.preventDefault();


			/* Disabled if anchor has class backlink */

			if ($(this).hasClass('js-base__back-link'))
			return false;


			/* Get target of anchor elements */

			var target:any = $(this).attr('href');
			target = $(target);
			

			/* Scroll to target */

			$('html, body').stop().animate({
				scrollTop: target.offset().top - 200 }
			, 300);
		});

		if(window.location.hash) {
			var target:any = window.location.hash;
			target = $(target);
			
			$('html, body').stop().animate({
				scrollTop: target.offset().top - 200 }
			, 300); 
		}
	}



	/**
	 * Function initBootstrapPopover():void
	 * initialises / calls bootstrap popover
	**/

	protected initBootstrapPopover():void {

		/* Call popover on click */

		$('[data-toggle="popover"]').popover();


		/* Hide popover on click */

		$('.e-button__clipboard').click(function() {
			$('[data-toggle="popover"]').popover('hide');
			$(this).addClass('e-button__clipboard--copy');
			setTimeout(function() {
				$('.e-button__clipboard').removeClass('e-button__clipboard--copy');
			}, 2000);
		});
	}

	/**
	 * Function initTableSorter():void
	 * initialises / calls the tablesorter (external lib)
	**/

	protected initTableSorter():void {

		/* Set responsive class for each table */

		$('.e-rte table').each(function() {
			var tableContent = $(this).html();
			var tableID = $(this).attr('id');
			var tableClass = $(this).attr('class');
			$(this).replaceWith(function () {
				return $('<div class="table-responsive"><table id="' + tableID + '" class="' + tableClass + '">' + tableContent + '</table></div>');
			});
		});
	

		/* Call tablesorter function */

		$(".js-main__tablesorter").each(function() {
			let self:any = $(this);
			var rowCount = $('tr', this).length;
			if(rowCount >= 5) {
				self.tablesorter(); 
			}
		});
	}



	/**
	 * Function initStickyElements():void
	 * initialises stickyfill (external lib)
	**/

	protected initStickyElements():void {

		/* Apply the polyfill for sticky elements */
		
		var elements = $('.sticky');
		Stickyfill.add(elements);
	}




	/**
	 * Function initClipboardElements():void
	 * initialises Clipboard (external lib)
	**/

	protected initClipboardElements():void {

		/* Apply clipboard function to elements */

		let clipboard = new ClipboardJS('.e-button__clipboard');
	}



	/**
	 * Function formErrorScrollHandler():void
	 * scrolls to error
	**/

	protected formErrorScrollHandler():void {
		var error:any;
		$('*[type=submit]').on('click', function(){
			error = 0;
			$("input, textarea, select").focus(function() {
				if(error === 0){
					error = 1
	
					$('html, body').animate({
						scrollTop: $(this).offset().top - 200
					},'slow'); 
				}
			});
		})
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the base class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Backlink */

		self.backLink();


		/* AnchorAnimation */

		self.anchorAnimation();


		/* Bootstrap popover */

		self.initBootstrapPopover();


		/* From error scroll handler */

		self.formErrorScrollHandler();
	}
}
/**
 * autocomplete-zip.ts
 * 
 * 
**/

class AutocompleteZip {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {	
	}
	
	

	/**
	 * Function initAutocompleteZip():void
	 * Initialize autocomplete
	**/

	public initAutocompleteZip(id: any, isocodea2:any) { 

		var input = document.getElementById(id);

		
		/* Chrome bug */ 
		
			var observerHack = new MutationObserver(function() {
				observerHack.disconnect();
				$("#contactsBundleZip").attr("autocomplete", "new-password");
			});

			observerHack.observe(input, {
				attributes: true,
				attributeFilter: ['autocomplete']
			});
			

		var options = {
			componentRestrictions: {'country':isocodea2},
			/* cities */
			types: ['(regions)']
		};

		var autocomplete = new google.maps.places.Autocomplete(input,options);


		/* Add Event listener - for 'place changed' */

		google.maps.event.addListener(autocomplete, 'place_changed', function() {

			var location = autocomplete.getPlace();
			var geocoder = new google.maps.Geocoder();
			lat = location['geometry']['location'].lat();
			lng = location['geometry']['location'].lng();
			var latlng = new google.maps.LatLng(lat,lng);

			geocoder.geocode({'latLng': latlng}, function(results: any) {
				var political = '';
				var locality = '';
				var postal_code = '';
				var addressComponents = results[0].address_components;
				//console.debug(addressComponents);
				for (var i = 0; i < addressComponents.length; i++) {
					if (addressComponents[i].types[0] == "postal_code") postal_code = results[0].address_components[i].long_name;
					//if (addressComponents[i].types[0] == "locality") locality = results[0].address_components[i].long_name;
					//if (addressComponents[i].types[0] == "political") political = results[0].address_components[i].long_name;
				}
				//$('#'+id).val(postal_code + ((political!='' && political!=locality)?(' ' + political + ','):'') + ' ' + locality);
				$('#'+id).val(postal_code);
			});
		});

	}
}
/**
 * filter.ts
 * Filter, that automatically generates buttons and associated filter options
**/



class Filter {

	/**
	 * Initalise class variables
	**/

	public filter:JQuery;
	private activeFilter: JQuery;
	private validFilterResult: boolean;
	private lastFilterRemoved: any;

	/**
	 * Constructor
	 * call 'new Filter()'
	**/

	constructor () {
		
		/**
		 * Initalise local constructor variables
		**/
		
		let self = this;
		self.filter = $('.js-e-filter');
		self.activeFilter = self.filter.find('.e-filter__selection');
		self.validFilterResult = false;

		
		/**
		 * Set parameters
		**/

		// self.accordionMode = accordionMode;



		/**
		 * Call functions
		**/

		self.initializeFilter();
		self.watchEvents();
	}



	/**
	 * Function initializeFilter():void
	 * Initalize filter
	 * gets filterable values and generates a dynamic html for filter buttons
	**/

	public initializeFilter():void {

		/**
		 * Initalise function variables
		**/
		let self = this;
		let storedFilter:any = null;


		/* Initalize webstorage */

		if (typeof(Storage) !== "undefined") {
			// ?
		} else {
			// Sorry! No Web Storage support..
		}

		// self.filter.each((index, element) => {

			var generatedHTML = '';

			/**
			 * Get filterable values
			**/
			
			// let filterInstance = $(element);
			let filterInstance = self.filter;
			let tableHeader = filterInstance.next().find('table').find('thead').find('th');
			let tableRows = filterInstance.next().find('table').find('tbody').find('tr');
			var filterColumns:object = {}; 
			var filterColumnsIndexArray:any[] = []; 
			var filterValuesText:object = {}; 
			var filterValuesIds:object = {}; 
			let activeFilter:any = filterInstance.find('.e_filter__active-filter');
			
			tableHeader.each((index, element) => {
				let tableHeaderInstance = $(element);
				let name: string = tableHeaderInstance.data('filterName');
				let displayName: string = tableHeaderInstance.text();
				let filterIsActive:boolean = false;
				
				/* For all bricks / compontents without active filter selection */
				if(activeFilter.length == 0)
					filterIsActive = true;

				if(name) {

					activeFilter.each((index:number, element:HTMLElement) => {
						if(name == $(element).data('active-filter'))
							filterIsActive = true;
					});

					if(filterIsActive == true) {
						filterValuesText[index] = [];
						filterValuesIds[index] = [];
						filterColumns[index] = {};
						filterColumns[index]['index'] = index;
						filterColumns[index]['name'] = name;
						filterColumns[index]['displayName'] = displayName;
						filterColumnsIndexArray.push(index);
					}
				}
			});

			tableRows.each((index, element) => {
				let tableRowsInstance = $(element);
				let tableCells = tableRowsInstance.find('td');

				tableCells.each((index, element) => {
					if(jQuery.inArray(index,filterColumnsIndexArray) !== -1){

						let elementText = $(element).data('filterValues');

						if(elementText) {
							let elmentTextArray = elementText.split(',');

							$.each(elmentTextArray, function(index, element){
								elmentTextArray[index] = element.trim();
							});
	
							filterValuesText[index].push.apply(filterValuesText[index],elmentTextArray);
	
							let elementIds = $(element).data('filterIds').toString();
							let elmentIdsArray = elementIds.split(',');
	
							$.each(elmentIdsArray, function(index, element){
								elmentIdsArray[index] = element.trim();
							});
	
							filterValuesIds[index].push.apply(filterValuesIds[index],elmentIdsArray);
						}
					}
				});
			});
			

			/* Remove duplicated entries from array */

			for(let key in filterValuesText) {
				filterValuesText[key] = this.unique(filterValuesText[key]);
			}

			for(let key in filterValuesIds) {
				filterValuesIds[key] = this.unique(filterValuesIds[key]);
			}

			/**
			 * Build HTML
			**/

			var acceptText = filterInstance.find('.filter-placehoder').text();
			for(let key in filterColumns) {
				if(filterValuesText[key].length > 1){
					generatedHTML += '<div class="e-filter__buttons" data-filter-column="' + filterColumns[key]['name'] + '">';
					generatedHTML += '<button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' + filterColumns[key]['displayName'] + '</button>';
					generatedHTML += '<div class="dropdown-menu e-form"><div class="dropdown-menu-scrollable">';
					
					for(let entry in filterValuesText) {
						if(entry === key) {
							 $.each(filterValuesText[entry], function (index, element) {
								generatedHTML += '<div class="e-form__checkbox-wrapper">';
								generatedHTML += '<input type="checkbox" name="check" data-filter-id="' + filterValuesIds[entry][index] + '" value="' + filterValuesText[entry][index] +'" id="check-' + filterColumns[key]['name'] + '-' + filterValuesIds[entry][index] +'">';
								generatedHTML += '<label for="check-' + filterColumns[key]['name'] + '-' + filterValuesIds[entry][index] +'">' + filterValuesText[entry][index] + '</label>';
								generatedHTML += '<span></span></div>'; 
							}); 
						}				
					}
					generatedHTML += '</div><button class="filter-apply">' + acceptText + '</button></div></div>	';
				}
			}
			filterInstance.find('.filter-placehoder').replaceWith(generatedHTML);
		// });

		/* Check if a filter for this page was already set */
		storedFilter = this.getLocalStore();

		if(storedFilter != null) {
			let minResult:boolean = false;

			this.filter.find('.e-filter__buttons').each(function(i, v) {
				var f: JQuery = $(v);
				var column: string = f.data('filter-column');
				var checkboxes: JQuery = f.find('input[type=checkbox]');


				/* Skip non existing filter options */

				if (!storedFilter[column] || $.isEmptyObject(storedFilter[column]) ) {
					return false;
				}


				checkboxes.each(function(i: number, c: HTMLInputElement) {
					var id: string = $(c).data('filterId') as string;
					if (storedFilter[column][id] == id ) {
						c.checked = true;
						minResult = true;
					}
				});
			});

			if(minResult) {
				/* Apply the filter once */

				this.applyFilters();
			}
		}
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the filter
	**/

	public watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/**
		 * Watch for clicks on filter dropdown menus 
		 * stops propagation
		**/

		self.filter.find('.dropdown-menu').on('click', function(e: JQueryEventObject) {
			e.stopPropagation();
		});



		/**
		 * Watch for clicks on filter apply buttons 
		 * search for checked "checkboxes"
		 * calls "applyFilters"
		**/

		self.filter.find('button.filter-apply').on('click', function() {
			var p: JQuery = $(this).parents('.e-filter__buttons');
			var selected: JQuery = p.find('input[type=checkbox]').filter(function(i: number, c: HTMLInputElement) { return c.checked; })
			p.data('selected', selected);

			self.applyFilters();
		});



		/**
		 * Restore checkbox state on close (to last applied)
		**/

		self.filter.find('.e-filter__buttons').on('show.bs.dropdown', function () {
			var selected: JQuery = $(this).find('input[type=checkbox]').filter(function(i: number, c: HTMLInputElement) { return c.checked; })
			$(this).data('selected', selected);
		});



		/**
		 * Resets checkbox states on close (to last applied)
		**/

		self.filter.find('.e-filter__buttons').on('hide.bs.dropdown', function () {
			var checkboxes: JQuery = $(this).find('input[type=checkbox]');
			var selected: JQuery = $(this).data('selected') as JQuery;

			checkboxes.each(function(i: number, checkbox: HTMLInputElement) {
				checkbox.checked = $(checkbox).is(selected);
			});
		});



		/**
		 * Watch for click on reset filter button
		 * resets all filter to unchecked state
		 * calls "applyFilters"
		**/

		self.activeFilter.find('button.filter-clear-all').on("click", function() {
			var checkboxes: JQuery = self.filter.find('input[type=checkbox]').each(function(i: number, c: HTMLInputElement) {
				c.checked = false;
			});
			self.applyFilters();
		});



		/**
		 * Watch for click on delete active filter
		 * Saves id for "lastFilterRemoved"
		**/

		self.filter.find('.e-filter__selection-error button').on('click', function() {
			if($(this).hasClass('filter-clear-all'))
				self.activeFilter.find('button.filter-clear-all').trigger('click');
			else
				self.resetLastFilter();
		});
	}



	/**
	 * Function addActiveFilter(text: string, checkbox: HTMLInputElement)
	 * Adds an active filter button below filter dropdowns
	**/

	public addActiveFilter(text: string, id: string, checkbox: HTMLInputElement) {
		var that: Filter = this;
		var existing: JQuery = this.activeFilter.find('button').filter(function() {
			return $(this).text() == text && $(this).data('filterId') == id;
		});

		if (existing.length === 0) {
			var button: JQuery = $('<button data-filter-id="' + id + '">').text(text);
			button.data('checkbox', checkbox);

			button.on("click", function() {
				var c: HTMLInputElement = $(this).data("checkbox");
				c.checked = false;
				/* Save last  */
				that.lastFilterRemoved = $(this).data('filter-id');
				that.applyFilters();
			});

			this.activeFilter.append(button);
			var textNode: Text = document.createTextNode(' ');
			this.activeFilter.append(textNode);
		}
		this.activeFilter.removeClass("d-none");
	}



	/**
	 * Function removeActiveFilter(text: string)
	 * Removes an active filter button below filter dropdowns
	**/

	public removeActiveFilter(text: string, id: string) {
		this.activeFilter.find('button').filter(function() {
			return $(this).text() == text && $(this).data('filterId') == id;
		}).remove();
		if (this.activeFilter.find('button').length == 1) {
			this.activeFilter.addClass("d-none");
		}
	}



	/**
	 * Function checkValidFilterResult()
	 * Checks if the result is of filtering is valid (>= one row as result)
	**/

	public checkValidFilterResult() {

		/**
		 * Initalise function variables
		**/

		let self = this;
		/* Filter result not valid - rerun filtering and delete all filters before*/
		if(self.validFilterResult == false) {
			self.filter.find('.e-filter__selection-error').removeClass("d-none");
			if(!self.lastFilterRemoved)
				self.filter.find('.e-filter__selection-error .filter-clear-last').addClass('d-none');
			else
				self.filter.find('.e-filter__selection-error .filter-clear-last').removeClass('d-none');
		} else {
			self.filter.find('.e-filter__selection-error').addClass('d-none');
		}
	}



	/**
	 * Function resetLastFilter()
	 * Readds the last removed filter
	**/

	public resetLastFilter() {
		let self = this;
		this.filter.find('.e-filter__buttons').each(function(i, v) {
			var f: JQuery = $(v);
			var checkboxes: JQuery = f.find('input[type=checkbox]');

			checkboxes.each(function(i: number, c: HTMLInputElement) {
				var id: string = $(c).data('filterId') as string;
				if (self.lastFilterRemoved == id ) {
					c.checked = true;
				}
			});
		});

		this.applyFilters();
	}

	/**
	 * Function setLocalStore(name: string, data: any)
	 * set a local storage
	**/

	public setLocalStore(data:any) {
		let filterName = $('html').data('page-id') + "-filter";
		let filterData = data;

		filterData = JSON.stringify(data);

		localStorage.setItem(filterName, filterData);
	}



	/**
	 * Function getLocalStore(name: string, data: any)
	 * set a local storage
	**/

	public getLocalStore():object {
		let filterName = $('html').data('page-id') + "-filter";

		if (localStorage.getItem(filterName) === null) {
			return null;
		} else {
			let storedFilter = localStorage.getItem(filterName);
			return JSON.parse(storedFilter);
		}
	}


	/**
	 * Function applyFilters(text: string)
	 * Applies the filter to the corresponding table values
	**/

	public applyFilters() {
		let table = this.filter.next().find('table');
		var that: Filter = this;
		var filters: object = {};
		var availableValues = {};
		that.validFilterResult = false;

		this.filter.find('.e-filter__buttons').each(function(i, v) {
			var f: JQuery = $(v);
			var column: string = f.data('filter-column');
			var checkboxes: JQuery = f.find('input[type=checkbox]');

			checkboxes.each(function(i: number, c: HTMLInputElement) {
				var value: string = $(c).val() as string;
				var id: string = $(c).data('filterId') as string;
				if (!filters[column]) { filters[column] = {}; }
				if (c.checked) {
					filters[column][id] = id;
					that.addActiveFilter(value, id, c);
				} else {
					delete filters[column][id];
					that.removeActiveFilter(value, id);
				}
			});
		});

		var columnMap: object = {};
		table.find('th').each(function(i, th) {
			var name: string = $(th).data('filterName');
			if (name) {
				columnMap[i] = name;
			}
		});

		table.find('tbody tr').each(function(i, tr) {
			var show: boolean = true;

			$(tr).find('td').each(function(i, td) {
				// filter not suitable, do not check other values
				if(show == false){
					return;
				}
				var column: string = columnMap[i];
				if(column) {
					// var text: string = $(td).text().trim();
					var elementIds = $(td).data('filterIds');
					if(elementIds) {
						elementIds = elementIds.toString();
						var elmentIdsArray = elementIds.split(',');
						let done = false;
						$.each(elmentIdsArray, function(index, element){
							if(done == true){
								return;
							}
							elmentIdsArray[index] = parseInt(element.trim());
							/* if filter column does not exists (maybe too few entries ) */
							if(typeof filters[column] === 'undefined')
								return;

							/* if elementID check is OK - stop the loop and go to next row */
							if( filters[column][elmentIdsArray[index]] && !$.isEmptyObject(filters[column])){
								show = true;
								done = true;
							}
							/* if elementID check is NOT OK - set visible to false and go to next ID of same element */
							else{
								if(!filters[column][elmentIdsArray[index]] && !$.isEmptyObject(filters[column])){
									show = false;
								}
							}
						});
					} else {
						show = false;
						if($.isEmptyObject(filters[column]))
							show = true;
					}
				} 
			});
			if (show) {
				$(tr).find('td').each(function(i, td) {
					var column: string = columnMap[i];
					// var text: string = $(td).text().trim();

					if (column) {
						var elementIds = $(td).data('filterIds');
						if(elementIds) {
							elementIds = elementIds.toString();
							var elmentIdsArray = elementIds.split(',');
							if (!availableValues[column]) availableValues[column] = [];
	
							$.each(elmentIdsArray, function(index, element){
								availableValues[column].push(parseInt(element.trim()));
								availableValues[column] = that.unique(availableValues[column]);
							});
						}
					}
				});
			}
			$(tr).toggle(show);
		});

		// disable checkboxes which would result in an empty table
		this.filter.find('.e-filter__buttons').each(function(i, v) {
			var f: JQuery = $(v);
			var column: string = f.data('filter-column');
			var checkboxes: JQuery = f.find('input[type=checkbox]');
			checkboxes.each(function(i: number, c: HTMLInputElement) {
				var value: string = $(c).val() as string;
				var id: string = $(c).data('filterId') as string;
				if (!availableValues[column] || $.isEmptyObject(filters[column]) ) {
				// if (!availableValues[column] || ($.isEmptyObject(filters[column]) && availableValues[column].indexOf(value) == -1)) {
					c.disabled = true;
					if($.inArray(id, availableValues[column]) >= 0) {
						c.disabled = false;
						that.validFilterResult = true;
					}
				} else {
					c.disabled = false;
					that.validFilterResult = true;
				}
			});
		});
		
		that.filter.find('.e-filter__buttons.show button.dropdown-toggle').dropdown('toggle');

		that.checkValidFilterResult();
		that.setLocalStore(filters);
	}
	


	/**
	 * Function unique(text: array)
	 * removes duplicate entries from a given array
	**/

	public unique(list:any) {
		var result:any = [];
		$.each(list, function(i, e) {
		  if ($.inArray(e, result) == -1) result.push(e);
		});
		return result;
	  }
}
/**
 * form-float.ts
 * 
 * 
 * 
 * 
 * 
 * !!!!!! THIS CLASS IS NOT IN USE !!!!
 * 
 * 
 * DATE: 13.12.2018
 *
 * 
 * 
 * 
 * 
 * 
 * 
**/

class Formfloat {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/


		let floatSelect = $('.e-form__select');
		let floatFile = $('.e-form__file');
		let floatTextarea = $('.e-form__textarea textarea');
		let floatTel = $('.e-form__input-tel');

		floatTel.each(function() {
			let self = $(this);
			let selfCurrentInput = self.find('input[type=tel]');
			let selfHiddenInput = self.find('input[type=hidden]');
			selfCurrentInput.on('input', function() {
				let values = '';
				selfCurrentInput.each(function() {
					values += $(this).val();
				});
				selfHiddenInput.val(values);
			});

		});

		
		/* Select */

		floatSelect.each(function() {
			let self = $(this);
			let selfSelect = $('select', this);
			let selfSelectID = selfSelect.attr('id');
			let selfSelectName = selfSelect.attr('name');


			/* Add input with select ID */

			selfSelect.after('<input type="text" id="'+ selfSelectID +'" placeholder=" " readonly required>');
			selfSelect.after('<input type="hidden" id="'+ selfSelectID +'VALUE" placeholder=" " name="'+ selfSelectName +'" readonly required>');


			/* Add list for select options */

			self.append('<ul class="e-form__select-options"></ul>');

			let selfSelectInput = self.children('input[type="text"]');
			let selfSelectInputHidden = self.children('input[type="hidden"]');
			let selfSelectOptionsWrapper = self.children('.e-form__select-options');

			let selfSelectOptions = selfSelect.find('option');


			/* Fill List with content from select options */

			selfSelectOptions.each(function() {
				
				let selfSelectOptionsContent = $(this).html();
				let selfSelectOptionValue = $(this).attr('value');
				let selfSelectOptionsSelected = null;
				if($(this).is(':selected')) {
					selfSelectOptionsSelected = "active";	
					selfSelectInput.val(selfSelectOptionsContent);
					selfSelectInputHidden.val(selfSelectOptionValue);
				} else {
					selfSelectOptionsSelected = "";	
				}

				selfSelectOptionsWrapper.append('<li class="' + selfSelectOptionsSelected + '" data-value="'+selfSelectOptionValue+'">'+ selfSelectOptionsContent +'</li>');
			});


			/* click on select group open List with options */

			self.on('mousedown touchstart', function () {
				if(selfSelectOptionsWrapper.is(":hidden")) {
					$(this).addClass('select-open');
				}
			});


			/* click on list - write selected option in input and close list */

			self.find('li').on('click', function() {
				let selfSelectOptionsValue = $(this).data("value");
				selfSelectInput.val($(this).html());
				selfSelectInputHidden.val(selfSelectOptionsValue);
				floatSelect.removeClass('select-open');
				$('.e-form__select-options li').removeClass('active');
				$(this).addClass('active');
			});


			/* original select remove because duplicate id */

			selfSelect.remove();
		});


		/* File */

		floatFile.each(function() {
			let self = $(this);
			let selfFile = $('input[type="file"]', this);
			let selfFileID = selfFile.attr('id');


			/* Add input with file ID */

			selfFile.after('<input type="text" placeholder=" " disabled class="e-form__file-new">');
			let selfFileNew = self.children('.e-form__file-new');


			/**
			 * 
			 * 
			 * 
			 * 
			 *  !!! REFACTOR IF IN USE !!!
			 * 
			 * 
			 * 
			 * 
			 * 
			**/
			/* Fill fake file-input with file-name */
			// selfFile.bind('change',function(){
			// 	$(this).blur();
			// 	var names = [];
			// 	for (var i = 0; i < $(this).get(0).files.length; ++i) {
			// 		names.push($(this).get(0).files[i].name);
			// 	}
			// 	selfFileNew.val(names); 
			// });
		});

		
		/* Textarea autoresize */

		floatTextarea.each(function() {
			var offset = this.offsetHeight - this.clientHeight;

			var resizeTextarea = function(el:any) {
				$(el).css('height', 'auto').css('height', el.scrollHeight + offset);
			};
			$(this).on('keyup input', function() { resizeTextarea(this); });
		});
	}
}
/**
 * to-top.ts
 * Logic of to-top button on page end
 * 
**/

class Header {

	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise local constructor variables
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Toggle small header */

		this.toggleSmallHeader();
		

		/* Watch events */

		this.watchEvents();
	}



    /**
     * Function toggleSmallHeader
    **/

    protected toggleSmallHeader () {
        let target = window;
		let documentHtml = $('html');
		
		// get scroll position based for all browsers
		let scrollPos  = target.pageYOffset || target.scrollY || $("body").scrollTop;
    
		if(scrollPos > 20) {
			if(!documentHtml.hasClass('js-l-header--small')) {
				documentHtml.addClass('js-l-header--small');
			}
		} else {
			if(documentHtml.hasClass('js-l-header--small')) {
				documentHtml.removeClass('js-l-header--small'); 
			}
		}
    }



	/**
	 * Function watchEvents():void
	 * Watches all events of the navigation class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/* Toggle HTML class' - * if page is scrolled */

		$(window).on('scroll',function () {
			self.toggleSmallHeader();
		});
	}
}
/**
 * navigation.ts
 * Logic of navigation
**/



class Landingpagenavigation {

    /**
     * Initalise class variables
    **/

    public animationDuration:number;
    

    
    /**
     * Constructor
    **/

    constructor (animationDuration: number=1000) {

        /**
         * Initalise local constructor variables
        **/
       
        let self = this;



        /**
         * Set parameters
        **/

		self.animationDuration = animationDuration;



        /**
         * Call functions
        **/
	   
        self.initializeMenu();
        self.watchEvents();
    }


   /**
     * Function initializeMenu():void
     * Initalize menu
     * Create HTML elements for landingpagenavigation
    **/

   public initializeMenu():void {

        /**
         * Initalise function variables
        **/

        let self = this;



        /**
         * Build navigation-frame for header- and burgernav 
        **/

        $('.js-landingpage__topic-nav').html('<ul class="l-topic-nav"></ul>');
        $('.js-landingpage__panel-nav ').html('<ul class="l-pagetree-panel"></ul>');



        /**
         * Build navigation-points for header- and burgernav 
        **/

        $('main section').each((index, element) => {
            if($(element).attr('id') != null){
                $('.l-topic-nav').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
                $('.l-pagetree-panel').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
            }
		});
		

		/* Set active state of menu-items */

        self.setActive();
    }


    
    /**
     * Function watchEvents():void
     * Watches all events of the landingpagenavigation and handles scrolling 
    **/

    public watchEvents():void {

        /**
         * Initalise function variables
        **/

        let self = this;


        
        /* Watch click on menu-items */

        $('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').on('click',function(event){
            let anchor = $(event.target).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
			let anchorScrollPosition =  anchorScrollTop - 150;
			
			if($('.js-l-body').hasClass('js-l-panel-nav--open')) {
				$('.l-panel-nav__backdrop').trigger('click');
			}

            $('html').stop().animate({
                scrollTop: anchorScrollPosition }, 300);
            event.preventDefault();
		});
		


		/* Watch scrolling of document for setting active state */

        $(window).on('scroll',function () {
            self.setActive();
        });
    } 



    /**
     * Function setActive():void
     * Set active state of menu-items
    **/
    
    public setActive():void {
		$('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').each((index, element) => {
			let anchor = $(element).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
            let parentItem = $(element).parent('li');
            let scrollCheckPoint = $(window).scrollTop() + 166;


            if(scrollCheckPoint >  (anchorScrollTop + $(anchor).outerHeight(true) ) ) {
                // Do nothing
            }
            else {
                if(anchorScrollTop < scrollCheckPoint)
                    parentItem.addClass('active').prev().removeClass('active');
                else 
                    parentItem.removeClass('active');
            }
        });
    }
}
/**
 * contacts.ts
 * 
 * 
**/

class Contacts {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {
	}



	/**
	 * Function initContacts():void
	 * Initialize contacts
	**/

    public initContacts(locationsArray:any, path:any) {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Add event handler */

		$('#contactsBundleCountrySelect').on("change", function() {
			let selectedCountry = $(this).val();
			self.showZip(selectedCountry, locationsArray);
		})

		$( "#js-x-contact__search-button" ).on( "click", function() {
			self.showContact(path);
			return false;  
		});

		self.showContact(path);

		var parameterCountry = GetURLParameter('country');
		self.showZip(parameterCountry, locationsArray);
	}	



	/**
	 * Function showContact():void
	 * 
	**/

	public showContact(path:any) {
		var str = $( "form" ).serialize();
		var url = path + str
		$.ajax({
			url: url,
			success: function( data ) {
				$( "#js-x-contact__result" ).html(data);
			}
		});
	}



	/**
	 * Function showZip():void
	 * 
	**/

	public showZip(country:any, locationsArray:any) {
		if (jQuery.inArray(country, locationsArray) != -1) {
			$('#js-x-contact__search-zip').show();  
			var autocompleteZip = new AutocompleteZip();
			autocompleteZip.initAutocompleteZip('contactsBundleZip', country); 
		} else {
			$('#js-x-contact__search-zip').hide();    
		}    
	}
}
/**
 * locations.ts
 * 
 * 
**/


/* Declare google globally */

declare var google: any; 
declare var lat: any; 
declare var lng: any; 
declare var bounds: any; 


class Locations { 

	/**
	 * Define class variables
	**/

	public markers:any = [];



	/**
	 * Constructor
	**/

	constructor () {	
	}



	/**
	 * Function setMarkers():void
	 * Set markers of map instance
	**/

	public setMarkers(map:any, locations:any) {
		
		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Do for every location */

		for (var i = 0; i < locations.length; i++) {
			var location = locations[i];
			var infoContent = location[5];
			var image = {
				url: location[4]
			};

			var locationInfowindow = new google.maps.InfoWindow({
				content: infoContent,
				maxWidth: 300
			});

			var marker = new google.maps.Marker({
				position: {lat: location[1], lng: location[2]},
				map: map,
				icon: image,
				zIndex: location[3],
				title: location[0],
				infowindow: locationInfowindow
			});

			self.markers.push(marker);

			google.maps.event.addListener(marker, 'click', function() {
				self.hideAllInfoWindows(map);
				this.infowindow.open(map, this);
			});

			var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
			bounds.extend(loc);
		}

		// var legend:any = document.getElementById('legend');
		var legend:any = document.getElementById('legend');
        for (var i = 0; i < locations.length; i++) {
			var location = locations[i];
			var name = location[6];
			var icon = location[4];

			if(!legend.innerHTML.includes(name)) {
				/* Append icon to legend and add name */ 
				var div = document.createElement('div');
				div.innerHTML = '<img src="' + icon + '"> ' + name;
				legend.appendChild(div);
			}
		}
		setTimeout(function() {
			$('#legend').show();
		}, 2000);
		
		
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);

		map.fitBounds(bounds);
		map.panToBounds(bounds);
		map.setOptions({maxZoom:16});
	}



	/**
	 * Function hideAllInfoWindows():void
	 * Hides information window of map instance
	**/

	public hideAllInfoWindows(map:any) {
		
		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Close every info window */

		self.markers.forEach(function(marker:any) {
			marker.infowindow.close(map, marker);
		}); 
	}


	/**
	 * Function initMap():void
	 * Initalize google map instance
	**/

	public initMap(mapid:any, locations:any) {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Create a map instance with several options */

		var map = new google.maps.Map(document.getElementById(mapid), {
			zoom: 15,
			center: {lat: 0.0, lng: 0.0 },
			streetViewControl: false,
			styles: [
				// modify style: https://mapstyle.withgoogle.com/
				{
					"featureType": "administrative.neighborhood", 
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#5c5c5c"
						},
						{
							"weight": "0.01"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#e0e0e0"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#fff1e7"
						},
						{
							"lightness": "0"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "labels.text",
					"stylers": [
						{
							"weight": "0.64"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"weight": "1"
						},
						{
							"lightness": "63"
						},
						{
							"color": "#fff5f0"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"lightness": "1"
						},
						{
							"color": "#f6e1c2"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#fd7539"
						},
						{
							"weight": "0.62"
						},
						{
							"lightness": "53"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#4e5757"
						},
						{
							"weight": "0.01"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"weight": "0.01"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"visibility": "on"
						},
						{
							"weight": "0.01"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"hue": "#0082ff"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#d8ebe5"
						},
						{
							"lightness": "-3"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"weight": "0.25"
						},
						{
							"color": "#888d8d"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"weight": "0.80"
						}
					]
				}
			]
		});

		bounds = new google.maps.LatLngBounds();

		self.setMarkers(map, locations);

		var hoechsterRegions = [
			{ lng: 8.97065353,  lat: 51.5067749 },
			{ lng: 8.9441061,  lat: 51.50978088 },
			{ lng: 8.93964958,  lat: 51.51317978 },
			{ lng: 8.9336834,  lat: 51.51602554 },
			{ lng: 8.92616844,  lat: 51.5173111 },
			{ lng: 8.92503452,  lat: 51.51799774 },
			{ lng: 8.91849232,  lat: 51.52045822 },
			{ lng: 8.90964127,  lat: 51.52345276 },
			{ lng: 8.90738773,  lat: 51.53162384 },
			{ lng: 8.91819954,  lat: 51.53425598 },
			{ lng: 8.92932892,  lat: 51.53746414 },
			{ lng: 8.93575096,  lat: 51.54039383 },
			{ lng: 8.93748665,  lat: 51.54747772 },
			{ lng: 8.94818306,  lat: 51.55168915 },
			{ lng: 8.95455933,  lat: 51.55188751 },
			{ lng: 8.95817852,  lat: 51.55405045 },
			{ lng: 8.95820045,  lat: 51.56069183 },
			{ lng: 8.98008442,  lat: 51.56597137 },
			{ lng: 8.99490929,  lat: 51.57001877 },
			{ lng: 9.00172615,  lat: 51.57215881 },
			{ lng: 8.99909306,  lat: 51.5776062 },
			{ lng: 9.01715946,  lat: 51.57509232 },
			{ lng: 9.01636982,  lat: 51.57912827 },
			{ lng: 9.01389599,  lat: 51.58477402 },
			{ lng: 9.01421738,  lat: 51.59398651 },
			{ lng: 8.99697113,  lat: 51.59816742 },
			{ lng: 8.98883629,  lat: 51.60529709 },
			{ lng: 8.98103619,  lat: 51.61215973 },
			{ lng: 8.97796154,  lat: 51.62030029 },
			{ lng: 8.98613548,  lat: 51.6289978 },
			{ lng: 8.98723698,  lat: 51.63592529 },
			{ lng: 8.98762035,  lat: 51.6473465 },
			{ lng: 8.98480415,  lat: 51.65507507 },
			{ lng: 8.98321342,  lat: 51.65898514 },
			{ lng: 8.97426414,  lat: 51.66975784 },
			{ lng: 8.97286892,  lat: 51.67651367 },
			{ lng: 8.98176098,  lat: 51.69009399 },
			{ lng: 8.97629547,  lat: 51.69841003 },
			{ lng: 8.99069881,  lat: 51.70308304 },
			{ lng: 8.99864292,  lat: 51.70458984 },
			{ lng: 8.99393845,  lat: 51.71128845 },
			{ lng: 8.9849453,  lat: 51.72154617 },
			{ lng: 8.982728,  lat: 51.72724152 },
			{ lng: 8.97910404,  lat: 51.73336792 },
			{ lng: 8.98384666,  lat: 51.73631668 },
			{ lng: 8.98950005,  lat: 51.75071335 },
			{ lng: 8.99765205,  lat: 51.75541306 },
			{ lng: 8.99108982,  lat: 51.76314926 },
			{ lng: 8.98173141,  lat: 51.76504517 },
			{ lng: 8.97036362,  lat: 51.77192688 },
			{ lng: 8.96905422,  lat: 51.77867889 },
			{ lng: 8.95926189,  lat: 51.78974152 },
			{ lng: 8.95284653,  lat: 51.80433273 },
			{ lng: 8.95283031,  lat: 51.82383347 },
			{ lng: 8.95362473,  lat: 51.83304214 },
			{ lng: 8.96182442,  lat: 51.83592224 },
			{ lng: 8.97123146,  lat: 51.83691025 },
			{ lng: 8.98144436,  lat: 51.83814621 },
			{ lng: 8.98963165,  lat: 51.84089661 },
			{ lng: 8.99921608,  lat: 51.84801865 },
			{ lng: 9.00479221,  lat: 51.85427856 },
			{ lng: 9.01657963,  lat: 51.85951996 },
			{ lng: 9.01729584,  lat: 51.86439133 },
			{ lng: 9.01138401,  lat: 51.87709045 },
			{ lng: 9.02552795,  lat: 51.8798027 },
			{ lng: 9.0282135,  lat: 51.87635422 },
			{ lng: 9.04201794,  lat: 51.87237549 },
			{ lng: 9.05571556,  lat: 51.87313461 },
			{ lng: 9.06003761,  lat: 51.87803268 },
			{ lng: 9.06996441,  lat: 51.88085175 },
			{ lng: 9.08093357,  lat: 51.88433838 },
			{ lng: 9.09062862,  lat: 51.88956451 },
			{ lng: 9.0969286,  lat: 51.89131165 },
			{ lng: 9.10105419,  lat: 51.89286041 },
			{ lng: 9.10310936,  lat: 51.89509201 },
			{ lng: 9.10769939,  lat: 51.89859009 },
			{ lng: 9.11240387,  lat: 51.9026413 },
			{ lng: 9.11463356,  lat: 51.90441132 },
			{ lng: 9.11920452,  lat: 51.90714645 },
			{ lng: 9.12421417,  lat: 51.91034317 },
			{ lng: 9.12904263,  lat: 51.91132736 },
			{ lng: 9.13249969,  lat: 51.90957642 },
			{ lng: 9.13508606,  lat: 51.90821838 },
			{ lng: 9.13290501,  lat: 51.90576553 },
			{ lng: 9.13114738,  lat: 51.9042244 },
			{ lng: 9.12968445,  lat: 51.90161514 },
			{ lng: 9.12635326,  lat: 51.90115738 },
			{ lng: 9.1242485,  lat: 51.90006256 },
			{ lng: 9.12385368,  lat: 51.89819336 },
			{ lng: 9.12583256,  lat: 51.8972168 },
			{ lng: 9.12803268,  lat: 51.89706421 },
			{ lng: 9.12961102,  lat: 51.89440918 },
			{ lng: 9.1316843,  lat: 51.89267731 },
			{ lng: 9.13689709,  lat: 51.89195633 },
			{ lng: 9.13302517,  lat: 51.890625 },
			{ lng: 9.13157177,  lat: 51.88721848 },
			{ lng: 9.12729359,  lat: 51.88125229 },
			{ lng: 9.13167,  lat: 51.87539291 },
			{ lng: 9.13430405,  lat: 51.8722229 },
			{ lng: 9.13719177,  lat: 51.86980057 },
			{ lng: 9.14453125,  lat: 51.87144852 },
			{ lng: 9.15668774,  lat: 51.87224579 },
			{ lng: 9.16362095,  lat: 51.86389923 },
			{ lng: 9.16995716,  lat: 51.8633728 },
			{ lng: 9.17559052,  lat: 51.8616333 },
			{ lng: 9.18418026,  lat: 51.85815811 },
			{ lng: 9.18774128,  lat: 51.85784531 },
			{ lng: 9.18972111,  lat: 51.85872269 },
			{ lng: 9.19471169,  lat: 51.85749054 },
			{ lng: 9.20030403,  lat: 51.85754395 },
			{ lng: 9.20239258,  lat: 51.85717773 },
			{ lng: 9.2067709,  lat: 51.85380936 },
			{ lng: 9.21151161,  lat: 51.85254669 },
			{ lng: 9.22168636,  lat: 51.85322952 },
			{ lng: 9.21926022,  lat: 51.86077118 },
			{ lng: 9.23495388,  lat: 51.86594391 },
			{ lng: 9.25302124,  lat: 51.86615753 },
			{ lng: 9.26196003,  lat: 51.86438751 },
			{ lng: 9.27349472,  lat: 51.86491394 },
			{ lng: 9.27974319,  lat: 51.85950089 },
			{ lng: 9.28749084,  lat: 51.85557556 },
			{ lng: 9.29808426,  lat: 51.85102463 },
			{ lng: 9.30347252,  lat: 51.85002518 },
			{ lng: 9.31236839,  lat: 51.85205078 },
			{ lng: 9.32597351,  lat: 51.8539238 },
			{ lng: 9.34381485,  lat: 51.85743332 },
			{ lng: 9.35250664,  lat: 51.86250305 },
			{ lng: 9.35928345,  lat: 51.86399841 },
			{ lng: 9.37514114,  lat: 51.8632431 },
			{ lng: 9.3794651,  lat: 51.86116791 },
			{ lng: 9.39410496,  lat: 51.85964966 },
			{ lng: 9.40262699,  lat: 51.85622787 },
			{ lng: 9.41373444,  lat: 51.85661697 },
			{ lng: 9.42522717,  lat: 51.85632324 },
			{ lng: 9.44344807,  lat: 51.85961533 },
			{ lng: 9.45460033,  lat: 51.86053085 },
			{ lng: 9.45951939,  lat: 51.86208725 },
			{ lng: 9.46010303,  lat: 51.86074066 },
			{ lng: 9.46121693,  lat: 51.85837555 },
			{ lng: 9.46125889,  lat: 51.85591888 },
			{ lng: 9.45991421,  lat: 51.85436249 },
			{ lng: 9.45484829,  lat: 51.85102463 },
			{ lng: 9.44890594,  lat: 51.84915543 },
			{ lng: 9.4454565,  lat: 51.84754944 },
			{ lng: 9.44217682,  lat: 51.84493637 },
			{ lng: 9.43625355,  lat: 51.84231186 },
			{ lng: 9.43331432,  lat: 51.84066772 },
			{ lng: 9.43312645,  lat: 51.83941269 },
			{ lng: 9.43382168,  lat: 51.83761215 },
			{ lng: 9.43581772,  lat: 51.83547974 },
			{ lng: 9.44081402,  lat: 51.83169937 },
			{ lng: 9.44217873,  lat: 51.82921982 },
			{ lng: 9.44027901,  lat: 51.82473373 },
			{ lng: 9.43520451,  lat: 51.8200798 },
			{ lng: 9.43171978,  lat: 51.81827927 },
			{ lng: 9.42733002,  lat: 51.81049347 },
			{ lng: 9.43019295,  lat: 51.80722427 },
			{ lng: 9.43589687,  lat: 51.80472946 },
			{ lng: 9.43636703,  lat: 51.79701614 },
			{ lng: 9.44815731,  lat: 51.7947998 },
			{ lng: 9.4352932,  lat: 51.78771973 },
			{ lng: 9.42362022,  lat: 51.78012085 },
			{ lng: 9.41132736,  lat: 51.77156448 },
			{ lng: 9.40427876,  lat: 51.76170731 },
			{ lng: 9.39970875,  lat: 51.75881195 },
			{ lng: 9.39082336,  lat: 51.75817108 },
			{ lng: 9.38456917,  lat: 51.75603485 },
			{ lng: 9.38469028,  lat: 51.75404739 },
			{ lng: 9.38412857,  lat: 51.75091171 },
			{ lng: 9.38216782,  lat: 51.74873352 },
			{ lng: 9.37859154,  lat: 51.74680328 },
			{ lng: 9.37583351,  lat: 51.74459839 },
			{ lng: 9.37535667,  lat: 51.74278641 },
			{ lng: 9.37607288,  lat: 51.74077606 },
			{ lng: 9.37765312,  lat: 51.7389679 },
			{ lng: 9.38167667,  lat: 51.73682785 },
			{ lng: 9.38841534,  lat: 51.73502731 },
			{ lng: 9.39243889,  lat: 51.73342133 },
			{ lng: 9.39441586,  lat: 51.73197174 },
			{ lng: 9.39481258,  lat: 51.73052597 },
			{ lng: 9.39426517,  lat: 51.72919083 },
			{ lng: 9.38814735,  lat: 51.72340012 },
			{ lng: 9.38716793,  lat: 51.72141266 },
			{ lng: 9.38741302,  lat: 51.71907806 },
			{ lng: 9.38909721,  lat: 51.71592331 },
			{ lng: 9.39154625,  lat: 51.71289825 },
			{ lng: 9.39338493,  lat: 51.71110535 },
			{ lng: 9.39966011,  lat: 51.7061348 },
			{ lng: 9.40141296,  lat: 51.70371246 },
			{ lng: 9.40166473,  lat: 51.70165634 },
			{ lng: 9.40122986,  lat: 51.69986343 },
			{ lng: 9.39983082,  lat: 51.69785309 },
			{ lng: 9.39497375,  lat: 51.69454956 },
			{ lng: 9.38785839,  lat: 51.6906662 },
			{ lng: 9.38670921,  lat: 51.68910217 },
			{ lng: 9.3871994,  lat: 51.68741226 },
			{ lng: 9.3884306,  lat: 51.68558884 },
			{ lng: 9.39137745,  lat: 51.68345642 },
			{ lng: 9.39357471,  lat: 51.68177795 },
			{ lng: 9.39487553,  lat: 51.68004608 },
			{ lng: 9.39558697,  lat: 51.6775589 },
			{ lng: 9.39575672,  lat: 51.67472839 },
			{ lng: 9.39459419,  lat: 51.67253876 },
			{ lng: 9.39025688,  lat: 51.67119217 },
			{ lng: 9.38547993,  lat: 51.67007828 },
			{ lng: 9.38167858,  lat: 51.66810608 },
			{ lng: 9.37986755,  lat: 51.66646194 },
			{ lng: 9.37851906,  lat: 51.66422653 },
			{ lng: 9.37818146,  lat: 51.66204834 },
			{ lng: 9.37800407,  lat: 51.65808487 },
			{ lng: 9.37738323,  lat: 51.65624619 },
			{ lng: 9.37553978,  lat: 51.65356445 },
			{ lng: 9.37490082,  lat: 51.65192795 },
			{ lng: 9.37651443,  lat: 51.64984131 },
			{ lng: 9.38064861,  lat: 51.64756775 },
			{ lng: 9.39047241,  lat: 51.64620972 },
			{ lng: 9.40789413,  lat: 51.64762497 },
			{ lng: 9.42341423,  lat: 51.64877319 },
			{ lng: 9.42487526,  lat: 51.64997482 },
			{ lng: 9.44045734,  lat: 51.65039444 },
			{ lng: 9.43794441,  lat: 51.64771652 },
			{ lng: 9.44244003,  lat: 51.64532089 },
			{ lng: 9.44150829,  lat: 51.64245987 },
			{ lng: 9.42013073,  lat: 51.62918472 },
			{ lng: 9.39797115,  lat: 51.62489319 },
			{ lng: 9.3826437,  lat: 51.62628174 },
			{ lng: 9.37630749,  lat: 51.62637329 },
			{ lng: 9.37200069,  lat: 51.62287521 },
			{ lng: 9.35378551,  lat: 51.61895752 },
			{ lng: 9.34126282,  lat: 51.61520004 },
			{ lng: 9.33697891,  lat: 51.61199188 },
			{ lng: 9.34965897,  lat: 51.60429001 },
			{ lng: 9.35626793,  lat: 51.59767532 },
			{ lng: 9.36739349,  lat: 51.59412384 },
			{ lng: 9.37352276,  lat: 51.59101486 },
			{ lng: 9.3663187,  lat: 51.58734512 },
			{ lng: 9.35585785,  lat: 51.58228683 },
			{ lng: 9.34837532,  lat: 51.57659912 },
			{ lng: 9.34606647,  lat: 51.57026291 },
			{ lng: 9.33622551,  lat: 51.5632019 },
			{ lng: 9.33541489,  lat: 51.55989075 },
			{ lng: 9.32053566,  lat: 51.55303192 },
			{ lng: 9.31736946,  lat: 51.55155945 },
			{ lng: 9.31791306,  lat: 51.55063629 },
			{ lng: 9.31996822,  lat: 51.5470314 },
			{ lng: 9.31937981,  lat: 51.54636002 },
			{ lng: 9.31850815,  lat: 51.5459938 },
			{ lng: 9.3187418,  lat: 51.54421234 },
			{ lng: 9.31611156,  lat: 51.54380035 },
			{ lng: 9.31573009,  lat: 51.54229736 },
			{ lng: 9.31638241,  lat: 51.54151535 },
			{ lng: 9.31584549,  lat: 51.54016113 },
			{ lng: 9.31423187,  lat: 51.53951645 },
			{ lng: 9.31428051,  lat: 51.5376358 },
			{ lng: 9.31108379,  lat: 51.5373497 },
			{ lng: 9.31162643,  lat: 51.53471756 },
			{ lng: 9.30996895,  lat: 51.53091049 },
			{ lng: 9.31473064,  lat: 51.52635574 },
			{ lng: 9.31563854,  lat: 51.52301788 },
			{ lng: 9.31032658,  lat: 51.51888657 },
			{ lng: 9.30888367,  lat: 51.51529694 },
			{ lng: 9.30742359,  lat: 51.51428604 },
			{ lng: 9.3015213,  lat: 51.51439667 },
			{ lng: 9.29477692,  lat: 51.51467133 },
			{ lng: 9.28590202,  lat: 51.51438141 },
			{ lng: 9.27579784,  lat: 51.5112648 },
			{ lng: 9.26943016,  lat: 51.50712585 },
			{ lng: 9.26614666,  lat: 51.50123215 },
			{ lng: 9.26207638,  lat: 51.49874115 },
			{ lng: 9.25314236,  lat: 51.49806976 },
			{ lng: 9.24069691,  lat: 51.49718475 },
			{ lng: 9.23687935,  lat: 51.49238205 },
			{ lng: 9.23674583,  lat: 51.49040222 },
			{ lng: 9.23589325,  lat: 51.49050903 },
			{ lng: 9.23428535,  lat: 51.49077225 },
			{ lng: 9.22876263,  lat: 51.49035263 },
			{ lng: 9.22839546,  lat: 51.48955536 },
			{ lng: 9.22693253,  lat: 51.48878479 },
			{ lng: 9.22595978,  lat: 51.48903656 },
			{ lng: 9.22515106,  lat: 51.4890976 },
			{ lng: 9.22464657,  lat: 51.4885788 },
			{ lng: 9.22365665,  lat: 51.48674393 },
			{ lng: 9.22333336,  lat: 51.48499298 },
			{ lng: 9.22138023,  lat: 51.48423386 },
			{ lng: 9.21755791,  lat: 51.47946167 },
			{ lng: 9.21833801,  lat: 51.47200394 },
			{ lng: 9.18136883,  lat: 51.46533966 },
			{ lng: 9.16814804,  lat: 51.45528412 },
			{ lng: 9.15840816,  lat: 51.44309616 },
			{ lng: 9.13406467,  lat: 51.44852448 },
			{ lng: 9.08641624,  lat: 51.44908142 },
			{ lng: 9.08294773,  lat: 51.46206284 },
			{ lng: 9.0922308,  lat: 51.49155807 },
			{ lng: 9.05856133,  lat: 51.50001907 },
			{ lng: 9.03059769,  lat: 51.50671005 },
			{ lng: 9.02289867,  lat: 51.5172348 },
			{ lng: 9.00114918,  lat: 51.51257324 },
			{ lng: 8.98466587,  lat: 51.50951004 },
			{ lng: 8.97065353,  lat: 51.5067749 }
		];

		var hoechsterPolygon = new google.maps.Polygon({
			paths: hoechsterRegions,
			strokeColor: '#e88518',
			strokeOpacity: 0.6,
			strokeWeight: 1,
			fillColor: '#e88518',
			fillOpacity: 0.1
		});

		hoechsterPolygon.setMap(map);
	}
}
// class Watchlist {
//     constructor () {

// 		var watchlistFunctions = new WatchlistFunctions('/rest-api/product-notes/', function(success: boolean, product: any, add: number) {

// 			var buttons = $('.x-watchlist__button');		
// 			buttons.removeAttr('disabled');

// 			if (success) {
// 				var note: JQuery = $('.l-sidebar__watchlist');
// 				var content: JQuery = note.find('.l-sidebar__content');
// 				var emptyMessage: JQuery = note.find('.l-sidebar__watchlist--empty');
// 				var badge: JQuery = note.find('.l-sidebar__badge');
// 				var badgeText: number = parseInt(badge.text(), 10);

// 				badge.text(badgeText + add);
// 				for(var i=0;i<3;i++) {
// 					note.fadeOut(250).fadeIn(250);
// 				}
	
// 				var buttonAdd: JQuery = buttons.filter('.add');
// 				var buttonDelete: JQuery = buttons.filter('.delete');

// 				if (add === 1) {
// 					note.removeClass('d-none');
// 					var newItem: JQuery = $('<li>').text(product.name); 
// 					content.find('ul').append(newItem);
// 					note.removeClass('d-none');
// 					buttonAdd.addClass('d-none');
// 					buttonDelete.removeClass('d-none');
// 					badge.removeClass('d-none');
// 					emptyMessage.addClass('d-none');
// 				} else if (add === -1) {
// 					content.find('li').filter(function() {
// 						return $(this).text() == product.name;
// 					}).remove();

// 					// $('#uid-heitec-products-product-watchlist').find('tr').filter(filterFunc).remove();
// 					// $('.x-products__watchlist-products').find('.x-products__watchlist-product').filter(filterFunc).remove();
					
// 					buttonAdd.removeClass('d-none');
// 					buttonDelete.addClass('d-none');

// 					changeDisabled();

// 					if ($('.x-products__watchlist').length > 0 && $('.x-products__watchlist-products').children().length == 0) {
// 						$('.x-products__watchlist-form').addClass('d-none');
// 						$('.x-products__watchlist-empty').removeClass('d-none');
// 					}

// 					if (badge.text() === "0") {
// 						badge.addClass('d-none');
// 						emptyMessage.removeClass('d-none'); 
// 						note.addClass('d-none');
// 					}
// 				}
// 			} else {

// 				if (add === 1) {
// 					alert('Das Produkt konnte nicht zur Merkliste hinzugefügt werden.');
// 				} else if (add === -1) {
// 					//$(this).parents('.x-products__watchlist-product').filter(filterFunc).removeClass('d-none');
// 					alert('Das Produkt konnte nicht entfernt werden, vermutlich befindet es sich bereits nicht mehr auf ihrer Merkliste.');
// 				}
// 			}
// 		});
// 		$('.x-watchlist__button').on('click', function() {
// 			console.log("click");
// 			if ($(this).is('.disabled')) { return false; }
// 			var productNr: string = $(this).data('product-nr');
// 			var productName: string = $(this).data('product-name');
// 			if ($(this).is('.add')) {
// 				watchlistFunctions.put({'nr': productNr, 'name': productName});
// 			} else if ($(this).is('.delete')) {
// 				watchlistFunctions.delete({'nr': productNr, 'name': productName});
// 			}
// 			$(this).attr('disabled', 'disabled');
// 		});
// 		$('.js-x-products__watchlist__delete').on('click', function() {
// 			$('.js-x-products__watchlist--choose:checked').each(function() {
// 				var productNr = $(this).val(),
// 				productName = $(this).parents('.x-products__watchlist-content').find('h5').text();
// 				watchlistFunctions.delete({'nr': productNr, 'name': productName});
// 				$(this).parents('.x-products__watchlist-product').addClass('d-none');
// 			});
// 		});


// 		var deleteTitle = $('.js-x-products__watchlist__delete').attr("title");
// 		var compareTitle = $('.js-x-products__watchlist__compare').attr("title");

// 		function changeDisabled() {
// 			var i = 0;
// 			$('.js-x-products__watchlist--choose').each(function() {
// 				var thisProp = $(this).prop('checked');
// 				if(thisProp == true) {
// 					i++;
// 				} 	
// 			});
// 			if (i >= 1) {
// 				console.log(i);
// 				$('.js-x-products__watchlist__delete').removeAttr("disabled").attr("title", '');     
// 			} else {
// 				$('.js-x-products__watchlist__delete').attr("disabled", "disabled").attr("title", deleteTitle);
// 			}
// 			if (i >= 5) {
// 				$('.js-x-products__watchlist__compare').attr("disabled", "disabled").attr("title", compareTitle);  
// 			} else if (i >= 2) {
// 				$('.js-x-products__watchlist__compare').removeAttr("disabled").attr("title", '');    
// 			} else {
// 				$('.js-x-products__watchlist__compare').attr("disabled", "disabled").attr("title", compareTitle);
// 			} 
			
// 		}

// 		$('.js-x-products__watchlist--all').on('click', function() {
// 			var thisProp = $(this).prop('checked');
// 			if(thisProp == true) {
// 				$('.js-x-products__watchlist--choose').prop('checked', true);
// 			} else {
// 				$('.js-x-products__watchlist--choose').prop( "checked", false );
// 			}
// 			changeDisabled()
// 		});
		
// 		$('.js-x-products__watchlist--choose').change(function() {
// 			changeDisabled()
// 			$('.js-x-products__watchlist--all').prop( "checked", false );
// 		}); 
		
//     }
// }
// class WatchlistFunctions {

// 	private url: string;
// 	private cb: Function;

//     constructor(url: string, callback: Function) {
// 		if (url.lastIndexOf('/') != url.length - 1) { url += '/'; }
// 		this.url = url;
// 		this.cb = callback;
// 	}

// 	public put(product: any) {
// 		this.doRequest("put", product);
// 	}

// 	public delete(product: any) {
// 		this.doRequest("delete", product);
// 	}

// 	private doRequest(method: string, product: any) {
// 		var that = this;
// 		var add: number = method == "put" ? 1 : -1;
// 		$.ajax({
// 			'url': this.url+product.nr,
// 			'dataType': 'json',
// 			'method': method,
// 			'success': function(data: any) { that.onSuccess(data, product, add); },
// 			'error': function() { that.onError(product, add); }
// 		});
// 	}
// 	private onSuccess(data: any, product: any, add: number) {
// 		if (data && data.success) {
// 			this.cb(true, product, add);
// 		} else {
// 			this.cb(false, product, add);
// 		}
// 	}
// 	private onError(product: any, add: number) {
// 		this.cb(false, product, add);
// 	}
// }
/**
 * content-cols.ts
 * 
 * 
**/

class ContentCols {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function initContentCols():void
	 * initialises content col elements (brick)
	**/

	protected initContentCols():void {
		$('.c-content-cols').not('.editmode .c-content-cols').each(function() {
        
            var $list = $(this),
                $items = $list.find('.c-content-cols__headline');
    
            $items.css( 'height', 'auto' );
    
            var maxHeight = 0;
            $items.each( function()
            {
                var itemHeight = Number($( this ).outerHeight());
				if (itemHeight > maxHeight) 
					maxHeight = itemHeight;
                if (itemHeight == 1) 
                    $(this).addClass('no-height');
                
            });
            $items.each(function(){
    
                if(!$(this).hasClass('no-height')) {
                    $(this).css('height', maxHeight);
                }
            });
		}); 
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the base class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;
		self.initContentCols(); 

		/**
		 * Call functions
		**/

		/* */
	}
}
window.addEventListener('load', function() {
    if($('.timeline').length) {
        window.ts = $('.timeline').Timeline({
            dotsPosition: 'top'
        });
        $('.timeline-custom.prev').click(function(){
            $(this).parent('.timeline-container').find('.slide-prev').click();
        });
        $('.timeline-custom.next').click(function(){
            $(this).parent('.timeline-container').find('.slide-next').click();
        });
    }
}, false);
/**
 * slider.ts
 * 
 * 
**/

class Slider {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* SlickSlider initialize - add several classes */

		$('.js-slider__page').each(function() {
			$(this).on('init', function(event, slick){
				var modalID = $(".slick-current", this).data("target");
				$(this).append('<span class="c-slider__modal-open" data-toggle="modal" data-target="'+ modalID +'"></span>');
			});
		});


		/* Initalize slick slider */

		self.initSlickSlider();


		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected initSlickSlider():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Initialize modals */

		$('.js-slider__modal').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			asNavFor: '.js-slider__page',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			customPaging : function(slider, i) {
				return '';
			}
		});


		/* Initialize slider */

		$(".js-slider__page").slick({
			dots: true,
			asNavFor: '.js-slider__modal',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			customPaging : function(slider, i) {
				return '';
			}
		});
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Watch modal show */

		$('.c-slider__modal').on('shown.bs.modal', function (e) {
			$('.js-slider__modal').slick('refresh');
			setTimeout(function(){
				$('.js-slider__modal').css("visibility", "visible");
			}, 200);
		});
	}
}
/**
 * hero.ts
 * Logic of hero component
**/



class Hero {

	/**
	 * Initalise class variables
	**/

	public animationDuration:number;
	public scrollFactor:number;
	public scrollFactorMobile:number;



	/**
	 * Constructor
	**/

	constructor (animationDuration: number=1000, scrollFactor: number=0.7) {

		/**
		 * Initalise local constructor variables
		**/
		
		let self = this;



		/**
		 * Set parameters
		**/

		self.animationDuration = animationDuration;
		self.scrollFactor = scrollFactor;
		self.scrollFactorMobile = 0.35;


		/**
		 * Call functions
		**/

		self.watchEvents();
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the hero component and handles scrolling 
	**/

	public watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/* Watch scroll event for text-box */
		
		$(window).scroll((e) => {
			this.parallax();
		});


		/* Watch click event on CTO button */

		$('.js-hero__scroll').on('click', (e) => {
			$('html, body').stop().animate({
				scrollTop: $('.pimcore_hero').next().offset().top }, this.animationDuration);   
			event.preventDefault();
		});
	} 



	/**
	 * Function parallax():void
	 * Set margin-top to text-box (small parallax effect)
	**/

	public parallax():void {

		/**
		 * Initalise function variables
		**/

		let self = this;
		let scrolled = $(window).scrollTop();


		/* Set margin of text-box */

		if(window.innerWidth > 1024)
			// $('.desktop .l-hero__design--full .l-hero__text').css('margin-top',(scrolled*this.scrollFactor)+'px');
			$('.l-hero__design--full .l-hero__text').css('margin-top',(scrolled*this.scrollFactor)+'px');
		else 
			// $('.mobile .l-hero__design--full .l-hero__text').css('margin-top',(scrolled*this.scrollFactorMobile)+'px'); 
			$('.l-hero__design--full .l-hero__text').css('margin-top',(scrolled*this.scrollFactorMobile)+'px'); 
	}
}
/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
 * 
**/



class PagetreePanel {

	/**
	 * Initalise class variables
	**/

	public accordionMode:any;
	public openCurrent:any;
	public animationDuration:any;
	public overviewpagesPossible:any;
	public menu:any;



	/**
	 * Constructor
	 * call 'new Panelmenu(true, true, 500, false)'
	**/

	constructor (accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {
		
		/**
		 * Initalise local constructor variables
		**/
		
		let self = this;
		self.menu = $('.js-l-pagetree-panel');


		
		/**
		 * Set parameters
		**/

		self.accordionMode = accordionMode;
		self.openCurrent = openCurrent;
		self.animationDuration = animationDuration;
		self.overviewpagesPossible = overviewpagesPossible;



		/**
		 * Call functions
		**/

		/* Initialize Menu */

		self.initializeMenu();


		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function initializeMenu():void
	 * Initalize menu
	 * Set classes for open-states to elements
	**/

	public initializeMenu():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/**
		 * Set additonal info classes to elements
		 * add toggle-class to main link only if overviewpages are possible
		**/

		self.menu.find('li').has('ul').addClass('l-pagetree-panel__parent').children('a')
			.append('<span class="js-l-pagetree-panel__parent--toggle l-pagetree-panel__parent--toggle"></span>');

		if (self.overviewpagesPossible == false){
			self.menu.find('li').has('ul').children('a').addClass('js-l-pagetree-panel__parent--toggle').children('.l-pagetree-panel__parent--toggle')
				.removeClass('js-l-pagetree-panel__parent--toggle');
		}

		self.menu.find('li.l-pagetree-panel__item--active').has('ul').addClass('l-pagetree-panel__item-parent--active');
		


		/**
		 * Set open-state to elements
		**/

		if(self.openCurrent == true) {
			$('li.js-l-pagetree-panel__item--active').each(function() {
				if ($(this).children('a').hasClass('active-trail')){
					$(this).addClass('js-l-pagetree-panel__item-parent--open');
				}
			});
			self.menu.find('li.js-l-pagetree-panel__item-parent--open').has('ul').children('ul').slideDown();
		}  
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the panelmenu and handles toggeling 
	**/

	public watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;
		let toggleButton = $('.js-l-pagetree-panel__parent--toggle');



		/**
		 * Watch for clicks on menu-parent item and 
		 * handle toogling of menus and submenus
		**/

		toggleButton.on('click touchend', function(e:any){
			let currentMenu = $(this).closest('li');
			e.preventDefault();



			/**
			 * Handling of click on already opened menu
			 **/

			if(currentMenu.hasClass('js-l-pagetree-panel__item-parent--open')){
				currentMenu.removeClass('js-l-pagetree-panel__item-parent--open')
					.children('ul').stop().slideUp(self.animationDuration)
					.find('li.js-l-pagetree-panel__item-parent--open')
					.removeClass('js-l-pagetree-panel__item-parent--open')
					.children('ul').stop().slideUp(self.animationDuration);
			} else {



				/**
				 * If accordion mode is active close all other panels in menu
				 * unless clicked menu is submenu of already opend 
				**/
				
				if(self.accordionMode) {
					if(!currentMenu.parents().hasClass('js-l-pagetree-panel__item-parent--open')){
						self.menu.find('li.js-l-pagetree-panel__item-parent--open')
						.removeClass('js-l-pagetree-panel__item-parent--open')
						.find('ul').stop().slideUp(self.animationDuration);
					}
				}



				/**
				 * Open clicked menu
				**/
				
				currentMenu.addClass('js-l-pagetree-panel__item-parent--open').children('ul').stop().slideToggle(self.animationDuration);
			}
		});
	}
}
/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
 * 
**/



class PagetreeSub {

	/**
	 * Define class variables
	**/


	/**
	 * Constructor
	**/
	constructor () {
			
		this.watchEvents(); 

	}



	/**
	 * Function toggleNav():void
	 *
	**/

	protected toggleNav():void {
		$('.js-l-pagetree-sub__opener').on('click touchend', function (e) {
			let subNavID = $(this).data('subnav-primary');

			$('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');
			$(this).addClass('l-pagetree-sub__item--open');
			$('.l-pagetree-sub__secondary').hide();
			$('*[data-subnav-secondary="' + subNavID + '"]').show();

			return false;
		});
	}



	/**
	 * Function activeNav():void
	 *
	**/

    protected activeNav():void {
        $('.js-l-pagetree-sub__opener').each(function (e) {
            if($(this).hasClass('l-pagetree-sub__item--active')) {
                let subNavID = $(this).data('subnav-primary');
                $('*[data-subnav-secondary="' + subNavID + '"]').show();   
            }
        });
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the base class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		self.toggleNav();
		self.activeNav();
		

		/* Watch navigation opener */

		$('.js-l-flyout-nav__opener').on('click touchend', function (e) {
			$('.l-pagetree-sub__secondary').hide();
			$('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');
			
			self.activeNav();
		});
	}
}

// /**
//  * cookie-message.ts
//  * 
//  * 
// **/

// class CookieMessage {

// 	/**
// 	 * Define class variables
// 	**/


// 	/**
// 	 * Constructor
// 	**/
// 	constructor () {

// 		/**
// 		 * Initalise function variables 
// 		**/

// 		let self = this;



// 		/**
// 		 * Call functions
// 		**/

// 		/* Watch events */

// 		self.watchEvents();
// 	}



// 	/*
// 	* Function showMessage
// 	*/

// 	protected showMessage():void {
// 		if(document.cookie.indexOf('hideCookieMessage=1') != -1){
// 			$('.l-cookie-message').addClass('close');
// 		}
// 		else{
// 			$('.l-cookie-message').removeClass('close');
// 		} 
// 	}



// 	/*
// 	* Function closeMessage
// 	*/

// 	protected closeMessage():void {
// 		$('.l-cookie-message__button-ok').on('click', function() {
// 			$(this).parents('.l-cookie-message').addClass('close'); 
// 			document.cookie = 'hideCookieMessage=1';
// 			return false;
// 		}); 
// 	}



// 	/**
// 	 * Function watchEvents():void
// 	 * Watches all events of the FlyoutNav class
// 	**/

// 	protected watchEvents():void {

// 		/**
// 		 * Initalise function variables 
// 		**/

// 		let self = this;


// 		/**
// 		 * Call functions
// 		**/

// 		/* Show cookie message */

// 		// self.showMessage();


// 		/* Close cookie message */

// 		// self.closeMessage();
// 	}
// }
/**
 * flyout-nav.ts
 * 
 * 
**/

class FlyoutNav {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/
	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Watch events */

		self.watchEvents();



		/* Get instance of PagetreeSecondary */

		let pagetreeSub = new PagetreeSub(); 
	}



	/**
	 * Function toggleNav():void
	 * 
	**/

	protected toggleNav():void {
		$('.js-l-flyout-nav__opener').on('click touchend', function (e) {
			// console.log('open');
			let mainNavID = $(this).data('mainnav-main'); 
			let mainNavID2 = 'navID' + mainNavID;
			// console.log(mainNavID);

			$('.l-pagetree-sub').hide();
			$('*[data-mainnav-sub="' + mainNavID + '"]').show();

			if($('.js-l-flyout-nav').hasClass('l-flyout-nav__open')) {
				let openID = $('.js-l-flyout-nav').attr('id');

				if (openID == mainNavID2) {
					$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
					$(this).removeClass('l-pagetree-main__item--open'); 
				} else {
					$('.js-l-flyout-nav').removeClass('l-flyout-nav__open');
					$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
					
					$('.js-l-flyout-nav').addClass('l-flyout-nav__open');  
					$('.js-l-flyout-nav').attr('id', mainNavID2);
					$(this).addClass('l-pagetree-main__item--open'); 
					
				}
			} else {
				$('.js-l-flyout-nav').addClass('l-flyout-nav__open');  
				$('.js-l-flyout-nav').attr('id', mainNavID2);
				$(this).addClass('l-pagetree-main__item--open');
			}

			return false;
		});

		$('.js-l-page__content').on('click touchend', function (e) {
			$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
			$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open');
		});
	}



	/**
	 * Function closeNav():void
	 * 
	**/

	protected closeNav():void {
		$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
		$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Toogle navigation */

		self.toggleNav();


		/* Close menu on resize & scroll */

		$(window).scroll(() => {
			this.closeNav();
		});
		
		$(window).on('resize',function () {
			$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
			$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
		});
	}
}
/**
 * pageloader.ts
 * Logic of pageloader (spinner)
 * 
**/

class pageLoader {

   /**
     * Define class variables
    **/

   public maximumTimeVisible:number; 



    /**
     * Constructor
    **/

    constructor (maximumTimeVisible: number=3000) {

        /**
         * Initalise local constructor variables
        **/

        let self = this;



        /**
         * Set parameters
        **/

        self.maximumTimeVisible = maximumTimeVisible;



        /**
         * Call functions
        **/

        /* Hide loader after page is fully loaded */
         $(window).on('load', function(){
            self.hideLoader();
        });

        /* Hide loader after maximumtime if page is loading too long */
        setTimeout(function() {
            self.hideLoader();   
        }, self.maximumTimeVisible);
    }



    /**
     * Function hideLoader():void
     * hide spinner after page is fully loaded
    **/

    protected hideLoader():void {
         $('.js-l-pageloader').fadeOut(100); 
    }
}
/**
 * navigation.ts
 * Logic of navigation
**/



class Panelnav {

    /**
     * Initalise class variables
    **/

    public scrollbarWidth:any;
    

    
    /**
     * Constructor
    **/

    constructor (accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {

        /**
         * Initalise local constructor variables
        **/
       
        let self = this;



        /**
         * Call functions
        **/

        self.measureScrollbar();
        self.watchEvents();



        /**
         * Get instance of panelmenu
        **/

        let pagetreePanel = new PagetreePanel(accordionMode, openCurrent, animationDuration, overviewpagesPossible);
    }


    
    /*
    * Function measureScrollbar():void
    * Measures scrollbar width of browser and adds a div 
    * that prevents jumping of content
    */

    public measureScrollbar():void {

        /**
         * Initalise function variables 
        **/

        let self = this;
        let scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        self.scrollbarWidth = window.innerWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }



    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/

    public watchEvents():void {

        /**
         * Initalise function variables 
        **/

        let self = this;



        /**
         * Toggle bodyclass 'js-l-panelmenu--open' 
        **/

		$('.js-l-pagetree-panel__opener, .js-l-panel-nav__backdrop, .js-panel-nav__close').on('click touchend', function (e) {
			e.preventDefault();
			if($('.js-l-body').hasClass('js-l-panel-nav--open')) {
				$('.js-l-body').removeClass('js-l-panel-nav--open').css({marginRight: '0px'});
				$('.l-header__wrapper').css({paddingRight: '0px'});
			} else {
				$('.js-l-body').addClass('js-l-panel-nav--open').css({marginRight: self.scrollbarWidth + 'px'});
				$('.l-header__wrapper').css({paddingRight: self.scrollbarWidth + 'px'});
			}
			// $('html').toggleClass('openNavi');
			$('.js-l-panel-nav__backdrop').fadeToggle();
		});
    }
}
/**
 * sidebar.ts
 * 
 * 
**/

class Sidebar {

	/**
	 * Define class variables
	**/


	/**
	 * Constructor
	**/

	constructor () {
		
		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Watch events */

		self.watchEvents();
	}



	/*
	* Function openSidebar
	* opens sidebar on click
	*/

	protected openSidebar():void {
		$('.l-sidebar__icon').on('click', function() {
			$('.l-sidebar__item').not($(this).parent()).removeClass('open');
			$(this).parent('.l-sidebar__item').toggleClass('open'); 
			
		});
	}



	/*
	* Function hideSidebar
	* hides sidebar on click
	*/

	protected hideSidebar():void {
		let windowHeight = $(window).height();

		if(windowHeight <= 500) {
			$(".l-sidebar").hide();
		} else {
			$(".l-sidebar").show();
		}
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the navigation class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/**
		 * Call functions
		**/

		self.openSidebar();
		self.hideSidebar();
		

		/* Hide sidebar on resize */

		$(window).on('resize',function () {
			self.hideSidebar();
		});
	}
}
/**
 * to-top.ts
 * Logic of to-top button on page end
 * 
**/

class toTop {

	/**
	 * Define class variables
	**/

	public animationDuration:number; 



	/**
	 * Constructor
	**/

	constructor (animationDuration: number=900) {

		/**
		 * Initalise local constructor variables
		**/

		let self = this;



		/**
		 * Set parameters
		**/

		self.animationDuration = animationDuration;



		/**
		 * Call functions
		**/

		this.checkVisibility();
		this.watchEvents();
	}



	/**
	 * Function initAction():void
	 * set/reset to-top classes
	**/

	protected checkVisibility():void {
		if($(window).scrollTop() > 9)
			$('.js-l-to-top').addClass('l-to-top--active');
		else 
			$('.js-l-to-top').removeClass('l-to-top--active');
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the navigation class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Toggle bodyclass 'js-l-to-top--visible' 
		 * if page is scrolled
		**/

		$(window).on('scroll',function () {
			self.checkVisibility();
		});


		
		/**
		 * Animate HTML to top 
		**/
		
		$('.js-l-to-top').on('click',function(event) {
			$('body,html').stop().animate({ 
				scrollTop: 0}, self.animationDuration);
			event.preventDefault();
		}); 
	}
}